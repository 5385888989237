import React, {useEffect, useRef} from "react";
import Anime from 'animejs/lib/anime.es.js';

export const AnimeComponent = ({children, onAnime}: React.PropsWithChildren<{onAnime: (anime: Anime, target: any) => any}>) => {
  const ref = useRef<any>();

  useEffect(() => {
    onAnime(Anime, ref.current)
  }, [onAnime, ref])

  return (<div className='anime' ref={ref}>{children}</div>)
};
