// @ts-ignore
import {Marker} from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import ReactDOM from 'react-dom';
import {ActionableEntityViewComponent} from "../components/ActionableEntityViewComponent";
import {GameRenderer} from "./GameRenderer";
import React from "react";
import {ActionableEntityInterface} from "../types/actionable";
import {ShopEntityViewComponent} from "../components/ShopEntityViewComponent";

export class EntityViewModel {
  private marker!: Marker;

  constructor(private gameRenderer: GameRenderer, protected entity: () => ActionableEntityInterface) {
  }

  get isHero() {
    return this.store.world.activeId === this.entity()._id;
  }

  onInit() {
    const entity = this.entity();

    // create a HTML element for each feature
    const el = document.createElement('div');

    const store = this.store;

    if (entity.__t === 'ActionableEntity') {
      ReactDOM.render((<ActionableEntityViewComponent isHero={this.isHero} id={entity._id} store={store}/>), el);
    }
    if (entity.__t === 'ShopEntity') {
      ReactDOM.render((<ShopEntityViewComponent id={entity._id}/>), el);
    }

    // make a marker for each feature and add to the map
    const marker = new Marker(el)
      .setLngLat(entity.location.coordinates)
      .addTo(this.map);

    this.marker = marker;

    el.addEventListener('click', (e) => {
      e.preventDefault();
      // console.log(JSON.stringify(this.store.world.entityMap.get(entity._id), null, 2));
    })
  }

  destroy() {
    this.marker.remove();
  }

  update () {
    if (!this.marker) {
      return;
    }

    const {lng, lat} = this.marker.getLngLat();
    const currentCoords = this.entity().location.coordinates;

    this.marker.setLngLat([
      lng + (currentCoords[0] - lng) * .05,
      lat + (currentCoords[1] - lat) * .05,
    ])
  }

  get store () {
    return this.gameRenderer.store;
  }

  get map () {
    return this.gameRenderer.map;
  }
}
