import React from "react";
import "./ViewComponent.scss"
import {IconComponent} from "../../../IconComponent";

export const ViewComponent = ({content, nav, onClose}: {content?: any, nav?: any, onClose?: any}) => {
  const renderContent = () => (
    <div className="view_component__content">
      {content}
    </div>
  )
  const renderNav = () => (
    <div className="view_component__nav">
      {onClose && <div className="view_component__close" onClick={onClose}>
          <IconComponent>✖</IconComponent>
      </div>}
      {nav}
    </div>
  )

  return (
    <div className='view_component'>
      {content && renderContent()}
      {(nav || onClose) && renderNav()}
    </div>
  )
}
