// @ts-ignore
import {Map} from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import {Store} from "../store";
import {BaseGameRenderer} from "./AbstractGameRenderer";
import {EntityViewModel} from "./EntityViewModel";
import {ActionableEntityInterface} from "../types/actionable";

export class GameRenderer extends BaseGameRenderer {
  entities: {[id: string]: EntityViewModel} = {};

  constructor(map: Map, public store: Store) {
    super(map);
  }

  update(map: Map) {
    Object.keys(this.entities).forEach(id => {
      if (this.store.world.entityMap.get(id)) {
        return;
      }

      this.entities[id].destroy();
      delete this.entities[id];
    })

    this.store.world.entities.forEach(entity => {
      const id = entity._id;
      if (this.entities[id]) {
        this.entities[id].update();
        return;
      }

      const vm = new EntityViewModel(this, () => this.store.world.entityMap.get(id) as any as ActionableEntityInterface);

      this.entities[id] = vm;

      vm.onInit();

      return;
    })
  }
}
