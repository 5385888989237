import {IObservableArray, makeAutoObservable} from "mobx";
import {Store} from "./index";

export type EffectType = {id: number, from: string, to: string, icon: string};

export class EffectStore {
  id: number = 0;
  effects: IObservableArray<EffectType> = [] as any;

  constructor(private root: Store) {
    makeAutoObservable(this);
  }

  add(effect: Omit<EffectType, 'id'>) {
    this.effects.push({
      ...effect,
      id: ++this.id
    });
  }

  remove(effect: EffectType) {
    this.effects.remove(effect);
  }
}
