import {makeAutoObservable, runInAction} from "mobx";
import {Store} from "./index";
import {API_URL} from "../config";

export class User {
  token: string|null = null;

  constructor(private root: Store) {
    this.token = localStorage.getItem('token') || null;
    makeAutoObservable(this);
  }

  register() {
    const email = 'foo@bar.com'
    this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: '/user/register',
      data: {
        email
      }
    });
    return this.login();
  }

  async login() {
    const response = await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: '/user/login',
      data: {
        email: 'foo@bar.com'
      }
    });

    runInAction(() => {
      this.token = response.data.token;
      localStorage.setItem('token', this.token as string)
    });
  }
}
