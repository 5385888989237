import React from "react";
import './EntityInventory.scss'
import {EntityInventoryItem, OnItemClick} from "./EntityInventoryItem";
import {EntityEquipmentInterface} from "../../../../types/equipment";
import {ItemInterface} from "../../../../types/item";


export const EntityEquipment = ({equipment, onItemClick}: {equipment: EntityEquipmentInterface, onItemClick: OnItemClick}) => {

  const renderSlot = (name: string, item?: ItemInterface) => (
    <div key={name}>
      <b>{name.toUpperCase()}</b>
      {item && <EntityInventoryItem key={item._id} item={item} onItemClick={onItemClick}/>}
    </div>
  )

  return (
    <div className='entity_inventory'>
      {Object.keys(equipment).filter(k => !['_id'].includes(k)).map(slot => renderSlot(slot, (equipment as any)[slot]))}
    </div>
  )
}
