import {observer} from "mobx-react";
import React, {useEffect, useRef} from "react";
import {AnimeComponent} from "../../AnimeComponent";
import {IconComponent} from "../../IconComponent";
import "./GameViewActionComponent.scss"

type GameViewActionComponentProps = {icon: string, count?: number, onClick: () => any, cooldown_end_at?: string|Date, isToggled?: boolean};

export const GameViewActionComponent = observer(({icon, count, onClick, cooldown_end_at, isToggled}: GameViewActionComponentProps) => {
  const ref = useRef<any>()

  useEffect(() => {
    if (!cooldown_end_at) {
      return;
    }

    const timeLeft = (new Date(cooldown_end_at).getTime()) - Date.now();
    console.log(timeLeft);

    timeLeft > 0 && ref.current && ref.current(timeLeft);
  }, [cooldown_end_at])

  return (
    <button className={`game_view_action is-animated ${isToggled ? 'is-toggled' : ''} `} onClick={onClick}>
      <AnimeComponent onAnime={(anime, target) => {
        ref.current = (duration) => anime({
          targets: target,
          easing: 'linear',
          scale: [0, 1],
          duration: duration
        })
      }}/>

      <IconComponent>{icon}</IconComponent>
      {/*todo: style*/}
      {count && count > 1 && <span className='game_view_action__count'>{count}</span>}
    </button>
  )
});
