import {API_URL} from "../config";
import {Store} from "./index";
import {makeAutoObservable, ObservableMap} from "mobx";
import {ItemDefinitionInterface} from "../types/item";


export class ItemStore {
  items: ObservableMap = new ObservableMap<string, ItemDefinitionInterface>()

  constructor(private root: Store) {
    makeAutoObservable(this);
  }

  async load() {
    const {data} = await this.root.request({
      method: 'get',
      baseURL: API_URL,
      url: `/item`
    });

    data.forEach(item => this.items.set(item.name, item))
  }

  getDefinition (name: string) {
    return this.items.get(name);
  }

  getIcon (name: string) {
    return this.items.get(name)?.icon
  }

  getType(name: string) {
    return this.items.get(name).type;
  }
}
