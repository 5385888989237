import React from "react";
import {observer} from "mobx-react";
import {store} from "../store";
import "./EntityViewComponent.scss"
import {IconComponent} from "./IconComponent";
import {shopStore} from "../store/shop.store";

export const ShopEntityViewComponent = observer(({id}: {id: string}) => {
  const entity = store.world.entityMap.get(id);

  if (!entity) {
    return null;
  }

  return (
    <div className='entity_view' onClick={() => shopStore.load(id)}>
      <div className="entity_view__icon">
        <IconComponent>🏪</IconComponent>
      </div>
      <div className="entity_view__stats">{entity.name}</div>
    </div>
  );
})
