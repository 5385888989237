import {makeAutoObservable} from "mobx";
import {request, store} from "./index";


class ShopStore {
  constructor() {
    makeAutoObservable(this);
  }

  async load (id: string) {
    await request({
      method: 'get',
      url: `/shop/${id}/`
    });

    //612a0c374e44b87075db7686

    await request({
      method: 'post',
      url: `/hero/${store.world.activeId}/shop/${id}/sell`,
      data: {
        item_id: '612a0c374e44b87075db7686'
      }
    })

    // await request({
    //   method: 'post',
    //   url: `/hero/${store.world.activeId}/shop/${id}/buy`,
    //   data: {
    //     item_name: 'apple'
    //   }
    // })

    await store.inventory.loadHeroInventory()
  }
}


export const shopStore = new ShopStore();
