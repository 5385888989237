import React from "react";
import {observer} from "mobx-react";
import {ActionableEntityInterface} from "../../../../types/actionable";

export const EntityStatsComponent = observer(({entity}: {entity: ActionableEntityInterface}) => {
  const renderStats = (o) => Object.keys(o).filter(v => !['_id'].includes(v)).map(key => {
    return (
      <tr key={key}>
        <th style={{textAlign: 'left'}}>{key}</th>
        <td>{(o as any)[key]}</td>
      </tr>
    )
  })

  if (!entity) {
    return null;
  }

  return (
    <div className='entity_stats'>
      <table>
        <tbody>
        {renderStats(entity.levelInfo)}
        {renderStats(entity.stats)}
        </tbody>
      </table>
    </div>
  )
});
