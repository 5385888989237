import React from "react";
import screenfull from "screenfull";
import {current} from "../vhcheck";

export function withFullScreen<T>(
  WrappedComponent: React.ComponentType<T>
) {
  return (props: T) => {
    const requestFullScreen = () => {
      // disable for now
      // if (screenfull.isEnabled) {
      //   const onChange = () => setTimeout(() => {
      //     current.recompute();
      //     console.log('screenfull onChange')
      //   }, 1500);
      //   screenfull.request()
      //     .then(() => onChange())
      //     .catch(e => console.log(e));
      //
      //   screenfull.on('change', onChange)
      // }
    }

    return (
      <div onClick={requestFullScreen}>
        <WrappedComponent {...props} />
      </div>
    );
  };
}
