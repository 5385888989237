import React, {useState} from "react";
import {store} from "../../../store";
import {observer} from "mobx-react";
import {GameViewComponent} from "./GameView";
import {HeroView} from "./HeroView";
import {OpenInventoryComponent} from "./EntityInventory/OpenInventoryComponent";

export const GameUiComponent = observer(() => {
  const [view, setView] = useState('game');
  const isInventoryOpen = Boolean(store.inventory.openInventoryId);

  if (!store.world.hero) {
    return null;
  }

  if (isInventoryOpen) {
    return <OpenInventoryComponent/>
  }

  return (
    <div className='game_ui'>
      {view === 'hero' && <HeroView onClose={() => setView('game')}/>}
      {view === 'game' && <GameViewComponent setView={v => setView(v)}/>}
    </div>
  )
})
