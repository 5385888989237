import React, {useState} from "react";
import {observer} from "mobx-react";
import {ViewComponent} from "../ViewComponent/ViewComponent";
import {store} from "../../../../store";
import {EntityInventory} from "./EntityInventory";

export const OpenInventoryComponent = observer(() => {
  const isOpen = Boolean(store.inventory.openedInventory);

  if (!isOpen) {
    return null;
  }

  function onItemClick(item: any) {
    store.inventory.pickup(store.inventory.openInventoryId as string, item._id)
  }

  return (
    <ViewComponent
      content={<EntityInventory items={store.inventory.openedInventory} onItemClick={onItemClick}/>}
      onClose={() => store.inventory.close()}
    />
  )
});
