import React from "react";
import {observer} from "mobx-react";
import { Redirect } from "react-router-dom";
import {store} from "../store";


export const withUser = (Component: React.ComponentType) => {
  return observer(() => {
    return store.user.token ? <Component/> : <Redirect to='/login'/>
  })
}
