import {io, Socket} from "socket.io-client";
import {makeAutoObservable, ObservableMap, runInAction} from "mobx";
import {store, Store} from "./index";
import {API_URL, WS_URL} from "../config";
import {ActionableEntityInterface} from "../types/actionable";
import {getDistance} from "geolib";

const socket = io(WS_URL);

socket.on('connect_error', e => console.error(e));
socket.on('connect', () => console.log('socket connected'));


export class WorldView {
  activeId?: string;
  targetId?: string;
  lastLocation?: [number, number];

  readonly entityMap: ObservableMap<string, ActionableEntityInterface> = new ObservableMap<string, ActionableEntityInterface>();
  private socket?: Socket;

  constructor(private root: Store) {
    const active = localStorage.getItem('active_hero')
    if (active) {
      this.activate(active)
    }
    makeAutoObservable(this);
    this.initSocketEvents();
  }

  private initSocketEvents() {
    socket.on('entity', (payload: ActionableEntityInterface[]) => {
      runInAction(() => {
        console.log('entity', payload)
        payload.forEach(e => {
          const id = e._id;
          this.entityMap.set(id, e)
        })
      })
    })

    socket.on('event', (payload) => {
      console.log('event', payload)
      if (payload.type === 'world_changed') {
        this.update();
      }

      if (payload.type === 'attack') {
        const weapon = payload.attacker.equipment?.weapon;
        const icon = weapon ? store.items.getIcon(weapon.name) : payload.attacker.attack_icon || '👊';
        this.root.effects.add({icon, from: payload.attacker._id, to: payload.target._id})
      }
      if (payload.type === 'use') {
        this.root.effects.add({
          icon: store.items.getIcon(payload.item.name),
          from: payload.target._id,
          to: payload.target._id
        })
      }
    })
  }

  get target() {
    return this.entityMap.get(this.targetId ?? '');
  }

  get hero() {
    const hero = this.entityMap.get(this.activeId ?? '');
    return hero!;
  }

  get entities() {
    return Array.from(this.entityMap.values());
  }

  setTarget (id: string) {
    this.targetId = id;
  }

  async attack () {
    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/auto-attack/${this.targetId}`,
    })
  }

  async removeTarget () {
    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/remove-target`,
    })
  }

  async move (lat: number, lng: number) {
    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/move`,
      data: {
        coordinates: [lat, lng]
      }
    });
    runInAction(() => {
      (this.hero.location.coordinates as any).replace([lat, lng]);
    })

    socket.emit('position', [lat, lng]);

    if (this.lastLocation) {
      const lastDistance = getDistance([lat, lng], this.lastLocation);

      if (lastDistance > 1000) {
        await this.update();
      }
    }

  }

  async update () {
    const activeId = this.activeId;

    if (!activeId) {
      return;
    }

    const {data} = await this.root.request({
      method: 'get',
      baseURL: API_URL,
      url: `/hero/${activeId}/world`,
    });

    runInAction(() => {
      this.entityMap.clear();
      data.forEach((entity: ActionableEntityInterface) => {
        this.entityMap.set(entity._id, entity);
      });
      this.lastLocation = this.hero.location.coordinates;
    })
  }

  activate(activeId: string) {
    localStorage.setItem('active_hero', activeId);
    this.activeId = activeId;
  }
}
