import React from "react";
import './EntityInfoComponent.scss'
import {observer} from "mobx-react";
import {ActionableEntityInterface} from "../../../../types/actionable";
import {store} from "../../../../store";

export const EntityInfoComponent = observer(({entity}: {entity: ActionableEntityInterface}) => (
  <div className='entity_info'>
    <div>{entity.name}</div>
    <div>{entity.health} / {entity.stats.maxHealth}</div>
    <div>{entity.mana} / {entity.stats.maxMana}</div>
  </div>
));

export const HeroInfoComponent = observer(() => {
  if (!store.world.hero) {
    return null;
  }
  const {hero} = store.world;
  return (
    <div className='entity_info is-inline'>
      <div>{hero.health} / {hero.stats.maxHealth}</div>
      <div>{hero.mana} / {hero.stats.maxMana}</div>
    </div>
  )
})

export const TargetInfoComponent = observer(() => {
  return store.world.target ? <EntityInfoComponent entity={store.world.target}/> : null;
})
