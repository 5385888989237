import {IObservableArray, makeAutoObservable} from "mobx";
import {Store} from "./index";

export type LogType = {id: number, type: 'error', text: string};

export class LogStore {
  id: number = 0;
  logs: IObservableArray<LogType> = [] as any;

  constructor(private root: Store) {
    makeAutoObservable(this);
  }

  add(log: Omit<LogType, 'id'>) {
    const newLog = {
      ...log,
      id: ++this.id
    };
    this.logs.push(newLog);

    setTimeout(() => this.remove(newLog), 2000);
  }

  remove(log: LogType) {
    const f = this.logs.find(l => l.id === log.id);
    if (f) {

      this.logs.remove(f);
    }
  }
}
