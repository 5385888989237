import {makeAutoObservable} from "mobx";
import axios, {AxiosRequestConfig} from "axios";
import {User} from "./user";
import {UserEntities} from "./user-entities";
import {WorldView} from "./world-view";
import {Inventory} from "./inventory";
import {EffectStore} from "./effect";
import {LogStore} from "./log";
import {ItemStore} from "./itemStore";
import {GameplayStore} from "./gameplay";
import {API_URL} from "../config";


// todo: remove this store ?
export class Store {
  user = new User(this);
  userEntities = new UserEntities(this);
  logs = new LogStore(this);
  world = new WorldView(this);
  inventory = new Inventory(this);
  effects = new EffectStore(this);
  items = new ItemStore(this);
  gameplay = new GameplayStore(this);

  constructor() {
    makeAutoObservable(this)
  }

  request(config: AxiosRequestConfig) {
    return request(config);
  }
}

export function request(config: AxiosRequestConfig) {
  return axios({
    baseURL: API_URL,
    ...config,
    headers: {
      ...(store.user.token ? {Authorization: `Bearer ${store.user.token}`} : {})
    }
  }).catch(e => {
    const text = e?.response?.data?.message;
    if (!text) {
      return;
    }
    store.logs.add({
      text,
      type: 'error'
    })
    throw e;
    return e;
  })
}

export const store = new Store();
