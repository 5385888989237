import "./SimpleHealthBarComponent.scss"


export const SimpleHealthBarComponent = ({current, max}: {current: number, max: number}) => {
  const percentage = current / max;

  return (
    <div className='SimpleHealthBarComponent'>
      <div className="SimpleHealthBarComponent__bar" style={{transform: `translate(-50%, 0) scale(${percentage * 2}, 1)`}}></div>
      <div className="SimpleHealthBarComponent__value">{current} / {max}</div>
    </div>
  )
};
