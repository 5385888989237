import MapboxComponent from "../MapboxComponent";
import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {Store} from "../store";
import {GameRenderer} from "../GameRenderer/GameRenderer";
import { Redirect } from "react-router-dom";
import './Game.scss'
import {GameUiComponent} from "../components/game/ui/GameUiComponent";
import {ParticleSystemComponent} from "../components/game/ParticleSystemComponent";
import {autorun} from "mobx";
import {withFullScreen} from "../hoc/withFullScreen";
import {withPreload} from "../hoc/withPreload";
import {watchGeolocation} from "../watchGeolocation";


const GameComponent = observer(({store}: { store: Store }) => {
  const mapRef = useRef<mapboxgl.Map>()
  const renderer = useRef<GameRenderer>()

  useEffect(() => {
    store.world.update();
    watchGeolocation();

    autorun(() => {
      const hero = store.world.hero;
      if (!hero || !mapRef.current) {
        return;
      }
      mapRef.current.flyTo({
        center: store.world.hero.location.coordinates
      });
    })

    const timeout = setInterval(() => store.world.update(), 60000);
    const mapUpdateTimeout = setInterval(() => {
      const map = mapRef.current;
      if (!map) {
        return;
      }

    }, 300);

    return () => {
      clearTimeout(timeout)
      clearTimeout(mapUpdateTimeout)
    }
  })

  const onMap = async (map: mapboxgl.Map) => {
    if (!renderer.current) {
      renderer.current = new GameRenderer(map, store);
    }
    mapRef.current = map;

    if (!store.world.activeId) {
      return;
    }

    await store.world.update();

    map.setCenter(store.world.hero.location.coordinates);

    map.on('click', ev => {
      if (ev.originalEvent.defaultPrevented) {
        return;
      }
      const coords = ev.lngLat.toArray();
      store.world.move(coords[0], coords[1]);
    })
  }

  if (!store.world.activeId) {
    return (<Redirect to={'/characters'}/>)
  }

  return (
    <div className='game_wrapper'>
      <MapboxComponent onMap={onMap}/>
      <ParticleSystemComponent/>
      <GameUiComponent/>
    </div>
  );
});

export const GamePage = withPreload(withFullScreen(GameComponent));
