import {IObservableArray, makeAutoObservable, observable, ObservableMap, runInAction} from "mobx";
import {store, Store} from "./index";
import {API_URL} from "../config";
import {ItemInterface} from "../types/item";


export class Inventory {
  readonly byEntity = new ObservableMap<string, IObservableArray<ItemInterface>>();
  openInventoryId?: string = '';

  get heroInventory () {
    return this.activeId ? this.byEntity.get(this.activeId) ?? [] : [];
  }

  get activeId() {
    return this.root.world.activeId
  }

  // todo: rename?
  get openedInventory () {
    return this.openInventoryId ? this.byEntity.get(this.openInventoryId) ?? [] : []
  }

  constructor(private root: Store) {
    makeAutoObservable(this);
  }

  async consume(itemId: string) {
    const target_id = store.world.targetId;

    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/inventory/consume`,
      data: {
        item_id: itemId,
        ...(target_id && {target_id})
      }
    });

    // this.activeId && this.removeItem(this.activeId, itemId);
    await this.loadHeroInventory();
  }

  async equip(itemId: string) {
    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/inventory/equip`,
      data: {
        item_id: itemId
      }
    });

    // this.activeId && this.removeItem(this.activeId, itemId);
    await this.loadHeroInventory();
  }

  private getOrCreate (entityId: string) {
    if (!this.byEntity.has(entityId)) {
      this.byEntity.set(entityId, observable.array())
    }

    return this.byEntity.get(entityId);
  }

  async load(entityId: string) {
    const {data} = await this.root.request({
      method: 'get',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/inventory/${entityId}`,
    });

    runInAction(() => {
      this.getOrCreate(entityId)?.replace(data);
    })
  }

  async open (entityId: string) {
    await this.load(entityId);

    runInAction(() => {
      this.openInventoryId = entityId;
    })
  }

  close() {
    this.openInventoryId = undefined;
  }

  remove(entityId: string) {
    this.byEntity.delete(entityId);
  }

  removeItem(entityId: string, itemId: string) {
    runInAction(() => {
      const items = this.byEntity.get(entityId);

      if (!items) {
        return;
      }

      const item = items.find((i: any) => i._id === itemId);

      if (item?.count && item.count > 1) {
        item.count --;
        this.getOrCreate(entityId)?.replace(items);
        return
      }

      this.getOrCreate(entityId)?.replace(items.filter(i => i._id !== itemId))
    })
  }

  async pickup(entityId: string, itemId: string) {
    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/inventory/${entityId}/pick`,
      data: {
        item_id: itemId
      }
    });

    await this.load(entityId);
    // this.removeItem(entityId, itemId)
    await this.loadHeroInventory();
  }

  async loadHeroInventory() {
    if (this.activeId) {
      await this.load(this.activeId)
    }
  }

  async toggleFavourite(name: string) {
    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/inventory/favourite`,
      data: {
        name
      }
    });
    await this.loadHeroInventory();
  }

  async deleteItem (itemId: string) {
    await this.root.request({
      method: 'delete',
      baseURL: API_URL,
      url: `/hero/${this.activeId}/inventory`,
      data: {
        item_id: itemId
      }
    });
    await this.loadHeroInventory();
  }
}
