import React, {useState} from "react";
import {observer} from "mobx-react";
import {store} from "../../../store";
import {EntityEquipment} from "./EntityInventory/EntityEquipment";
import {EntityStatsComponent} from "./EntityStatsComponent/EntityStatsComponent";
import {HeroInventory} from "./EntityInventory/HeroInventory";
import {TabComponent, TabsComponent} from "./TabsComponent/TabsComponent";
import {ViewComponent} from "./ViewComponent/ViewComponent";
import {IconComponent} from "../../IconComponent";

export const HeroView = observer(({onClose}: any) => {
  const [tab, setTab] = useState<'info'|'eq'|'backpack'>('info')

  return (
    <ViewComponent
      onClose={onClose}
      content={
        <>
          {tab === 'info' && <EntityStatsComponent entity={store.world.hero}/>}
          {tab === 'eq' && <EntityEquipment equipment={store.world.hero.equipment} onItemClick={() => null}/>}
          {tab === 'backpack' && <HeroInventory/>}
        </>
      }
      nav={
        <TabsComponent>
          <TabComponent active={tab === 'info'} onClick={() => setTab('info')}><IconComponent>ℹ</IconComponent></TabComponent>
          <TabComponent active={tab === 'eq'} onClick={() => setTab('eq')}><IconComponent>👕</IconComponent></TabComponent>
          <TabComponent active={tab === 'backpack'} onClick={() => setTab('backpack')}><IconComponent>🎒</IconComponent></TabComponent>
        </TabsComponent>
      }
    />
  )
})
