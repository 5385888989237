import {autorun} from "mobx";
import {store} from "./store";

export function watchGeolocation () {
  let watchId: number;
  let currentId: string | undefined;

  // todo: clear
  autorun(() => {
    if (!store.world.hero) {
      return;
    }

    if (store.world.activeId === currentId) {
      return
    }

    currentId = store.world.activeId;

    if(!('geolocation' in navigator)) {
      return;
    }

    watchId && navigator.geolocation.clearWatch(watchId)

    const update = position => store.world.move(position.coords.longitude, position.coords.latitude);

    navigator.geolocation.getCurrentPosition(update, () => {}, {enableHighAccuracy: true});
    watchId = navigator.geolocation.watchPosition(update, () => {}, {enableHighAccuracy: true});
  })
}
