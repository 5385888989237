import React, {useEffect, useState} from "react";
import {store} from "../store";

export function withPreload<T>(
  WrappedComponent: React.ComponentType<T>
) {
  return (props: T) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      (async () => {
        await store.items.load();
        await store.world.update();
        await store.inventory.loadHeroInventory();
        setLoaded(true);
      })()
    }, [])

    // todo: some loading component
    return loaded ? <WrappedComponent {...props} /> : <div>loading</div>;
  };
}
