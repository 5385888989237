import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import "./GameView.scss"
import {HeroInfoComponent, TargetInfoComponent} from "./EntityInfoComponent/EntityInfoComponent";
import {store} from "../../../store";
import { IconComponent } from "../../IconComponent";
import {AnimeComponent} from "../../AnimeComponent";
import {heroStore} from "../../../store/hero.store";
import {getDistanceBetweenEntities} from "../../../util";
import {ItemInterface} from "../../../types/item";
import {GameViewActionComponent} from "./GameViewActionComponent";

export const AttackActionComponent = observer(() => {
  const autoAttack = store.gameplay.isAutoAttacking;

  const icon = store.world.hero.equipment.weapon?.name && store.items.getIcon(store.world.hero.equipment.weapon?.name) || '⚔';

  return <GameViewActionComponent isToggled={autoAttack} onClick={() => store.gameplay.attack()} icon={icon} cooldown_end_at={store.world.hero?.cooldown_end_at}/>
})


export const FavouriteItemsComponent = observer(() => {
  return (
    <div style={{display: 'flex'}}>
      {heroStore.favItems.map((item: ItemInterface, i) => (<GameViewActionComponent key={i} cooldown_end_at={item.cooldown_end_at} icon={store.items.getIcon(item.name)} count={item.count} onClick={() => heroStore.useItem(item)}/>))}
    </div>
  )
})

// todo: typings
export const GameViewComponent = observer(({setView}: any) => {
  const renderTarget = () => {
    const distance = store.world.target ? getDistanceBetweenEntities(store.world.hero, store.world.target) : 0;
    return (
      <div className="game_view__target">
        <TargetInfoComponent/>
        <div>{`${distance}m`}</div>
      </div>
    )
  }

  return (
    <div className='game_view'>
      {store.world.target && renderTarget()}

      <div className="game_view__error">
        {store.logs.logs.map(log => (<div key={log.id}>{log.text}</div>))}
      </div>

      <div className="game_view__bottom_bar">
        <HeroInfoComponent/>
        <div className="game_view__actions">
          <button className="game_view__action" onClick={() => setView('hero')}>
            <IconComponent>ℹ</IconComponent>
          </button>
          <FavouriteItemsComponent/>
          <AttackActionComponent/>
        </div>
      </div>
    </div>
  )
})
