import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import anime from 'animejs/lib/anime.es.js';
import './ParticleSystemComponent.scss'
import {store} from "../../store";
import {EffectType} from "../../store/effect";
import {IconComponent} from "../IconComponent";

type ParticleComponentProps = {
  effect: EffectType
}

const ParticleComponent = observer(({effect}: ParticleComponentProps) => {
  const element = useRef<any>(null);

  useEffect(() => {
    const from = document.querySelector(`[data-id="${effect.from}"]`)
    const to = document.querySelector(`[data-id="${effect.to}"]`)

    if (!from || !to) {
      console.warn('targets not found')
      return;
    }

    const fromPosition = from.getBoundingClientRect();
    const toPosition = to.getBoundingClientRect();

    element.current.style.transform = `translate(${fromPosition.x + fromPosition.width / 2}px, ${fromPosition.y + fromPosition.height / 2}px)`;

    anime({
      from,
      targets: element.current,
      translateX: (toPosition.x + toPosition.width / 2) - (fromPosition.x + fromPosition.width / 2),
      translateY: (toPosition.y + toPosition.height / 2) - (fromPosition.y + fromPosition.height / 2),
      rotate: '1turn',
      scale: 2,
      duration: 800,
      complete: () => store.effects.remove(effect)
    });
  }, [effect, element]);

  return (
    <div ref={element} className='particle_system__particle'>
      <IconComponent>{effect.icon}</IconComponent>
    </div>
  )
})

export const ParticleSystemComponent = observer(() => {
  return (
    <div className='particle_system'>
      {store.effects.effects.map(e => (<ParticleComponent key={e.id} effect={e}/>))}
    </div>
  )
})
