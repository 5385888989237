import {makeAutoObservable, toJS} from "mobx";
import {store} from "./index";
import {ItemInterface} from "../types/item";


class HeroStore {
  constructor() {
    makeAutoObservable(this);
  }

  get hero () {
    return store.world.hero;
  }

  get favItems (): ItemInterface[] {
    const favourites = this.hero.favourites || [];

    return favourites.map(fav => store.inventory.heroInventory.find(i => i.name === fav)).filter(i => Boolean(i)) as any
  }

  isFavourite(item: ItemInterface) {
    const favourites = (this.hero as any)?.favourites || [];

    return favourites.includes(item.name);
  }

  async toggleFavourite(item: ItemInterface) {
    return store.inventory.toggleFavourite(item.name)
  }

  async useItem(item: ItemInterface) {
    if (store.items.getType(item.name) === 'usable') {
      await store.inventory.consume(item._id);
      return;
    }

    await store.inventory.equip(item._id);
  }

  async removeItem(item: ItemInterface) {
    return store.inventory.deleteItem(item._id);
  }
}


export const heroStore = new HeroStore();
