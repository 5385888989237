import React from "react";
import {observer} from "mobx-react";
import {Store} from "../store";
import "./EntityViewComponent.scss"
import {action} from "mobx";
import {IconComponent} from "./IconComponent";
import {SimpleHealthBarComponent} from "./SimpleHealthBarComponent";

export const ActionableEntityViewComponent = observer(({id, isHero, store}: {id: string, isHero: boolean, store: Store}) => {
  const entity = store.world.entityMap.get(id);

  if (!entity) {
    return null;
  }

  const isDead = entity.health <= 0;

  const {icon} = entity;

  if (isDead) {
    return (
      <div className='entity_view' data-id={entity._id} onClick={action(() => store.inventory.open(entity._id))}>
        <div className="entity_view__icon">
          <IconComponent>🪦</IconComponent>
        </div>
      </div>
    );
  }

  const showHealthbar = store.world.targetId === entity._id || entity.health < entity.stats.maxHealth;


  return (
    <div className={`entity_view ${store.world.targetId === entity._id ? 'is-active' : ''}`} data-id={entity._id} onClick={action(() => store.world.setTarget(entity._id))}>
      <div className="entity_view__icon">
        <IconComponent>{icon}</IconComponent>
      </div>
      {showHealthbar && <SimpleHealthBarComponent current={entity.health} max={entity.stats.maxHealth}/>}
    </div>
  );
})
