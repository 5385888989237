import React from "react";
import './EntityInventory.scss'
import {ItemInterface, ItemStatsInterface} from "../../../../types/item";
import {store} from "../../../../store";


export type OnItemClick = (item: ItemInterface) => any;

const ItemStatsComponent = ({stats}: {stats: ItemStatsInterface}) => {
  return (
    <>
      {
        Object.entries(stats).map(([stat, value]) => (
          <div key={stat}>
            <span>{stat}: </span>
            <span>{value}</span>
          </div>
        ))
      }
    </>
  )
}


export const EntityInventoryItem = ({item, onItemClick, children}: React.PropsWithChildren<{item: ItemInterface, onItemClick?: OnItemClick}>) => {
  const def = store.items.getDefinition(item.name);
  return (
    <div className='entity_inventory_item' onClick={() => onItemClick && onItemClick(item)}>
      <div className="entity_inventory_item__icon">
        {store.items.getIcon(item.name)}
        {
          item.count && item.count > 1 &&
          (<div className="entity_inventory_item__count">
            {item.count}
          </div>)
        }
      </div>
      <div className="entity_inventory_item__section" style={{marginRight: 'auto'}}>
        <div className="entity_inventory_item__name">{item.name}</div>
        {
          def?.stats && (
            <div className="entity_inventory_item__stats">
              <ItemStatsComponent stats={def.stats}/>
            </div>
          )
        }
      </div>
      <div className="entity_inventory_item__section">
        {children}
      </div>
    </div>
  )
}
