import {IObservableArray, makeAutoObservable, observable, runInAction} from "mobx";
import {Store} from "./index";
import {API_URL} from "../config";
import {ActionableEntityInterface} from "../types/actionable";

export class UserEntities {
  readonly entities: IObservableArray<ActionableEntityInterface> = observable([]);

  constructor(private root: Store) {
    makeAutoObservable(this);
  }

  async load() {
    const {data} = await this.root.request({
      method: 'get',
      baseURL: API_URL,
      url: '/hero'
    });

    runInAction(() => {
      this.entities.replace(data);
    })
  }

  async create() {
    await this.root.request({
      method: 'post',
      baseURL: API_URL,
      url: '/hero',
      data: {
        name: `Ludek ${Date.now()}`
      }
    });

    return this.load();
  }

}
