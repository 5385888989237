import {makeAutoObservable} from "mobx";
import {store, Store} from "./index";
import {heroStore} from "./hero.store";

export class GameplayStore {
  constructor(private root: Store) {
    makeAutoObservable(this);
  }

  get isAutoAttacking () {
    return Boolean(heroStore.hero.target)
  }

  get isTargetAlive () {
    return store?.world?.target?.health && store?.world?.target?.health > 0
  }

  get cooldownTimeLeft () {
    const {cooldown_end_at} = store.world.hero;

    return cooldown_end_at ? Math.max(0, (new Date(cooldown_end_at)).getTime() - Date.now()) : 0;
  }

  async attack () {
    if (this.isAutoAttacking) {
      return store.world.removeTarget();
    }

    await store.world.attack();
  }
}
