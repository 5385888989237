import {Store} from "../store";
import React from "react";
import {observer} from "mobx-react";
import { useHistory } from "react-router-dom";


export const LoginPage = observer(({store}: { store: Store }) => {
  const history = useHistory();

  const onLogin = async () => {
    await store.user.login();
    history.push('/characters')
  }

  return (<button onClick={onLogin}>login</button>)
})

