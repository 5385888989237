import React from "react";
import {observer} from "mobx-react";
import {Store} from "../store";
import {useEffect} from "react";
import {action} from "mobx";
import {useHistory} from "react-router-dom";
import {ActionableEntityInterface} from "../types/actionable";


export const CharactersPage = observer(({store}: { store: Store }) => {
  useEffect(() => {
    store.userEntities.load();
  }, [store.userEntities]);

  const history = useHistory();

  const onSelectCharacter = (entity: ActionableEntityInterface) => async () => {
    await store.world.activate(entity._id)
    history.push('/play')
  }

  return (
    <div>
      <table>
        <tbody>
        {store.userEntities.entities.map(entity => (<tr key={entity._id}>
          <td>{entity.icon}</td>
          <td>{entity.name}</td>
          <td>
            <button onClick={onSelectCharacter(entity)}>use</button>
          </td>
        </tr>))}
        </tbody>
      </table>
      <button onClick={action(() => store.userEntities.create())}>create</button>
    </div>
  )
});
