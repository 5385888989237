// @ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax


export abstract class BaseGameRenderer {
  constructor(public map: mapboxgl.Map) {
    setTimeout(() => this.scheduleUpdate(), 0);
  }

  private scheduleUpdate() {
    this.update(this.map);
    requestAnimationFrame(() => this.scheduleUpdate())
  }

  abstract update(map: mapboxgl.Map): void;
}
