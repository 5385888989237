import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {LoginPage} from "./pages/Login";
import {store} from "./store";
import {RegisterPage} from "./pages/Register";
import {GamePage} from "./pages/Game";
import {CharactersPage} from "./pages/Characters";
import {withUser} from "./hoc/withUser";

const HomePage = withUser(() => (<Redirect to='/characters'/>))

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/" exact>
            <HomePage/>
          </Route>
          <Route path="/login">
            <LoginPage store={store}/>
          </Route>
          <Route path="/register">
            <RegisterPage store={store}/>
          </Route>
          <Route path="/characters">
            <CharactersPage store={store}/>
          </Route>
          <Route path="/play">
            <GamePage store={store}/>
          </Route>
        </Switch>
    </Router>
  );
}
