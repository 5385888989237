import {observer} from "mobx-react";
import {EntityInventoryItem} from "./EntityInventoryItem";
import React, {useEffect} from "react";
import {store} from "../../../../store";
import {ItemInterface} from "../../../../types/item";
import {heroStore} from "../../../../store/hero.store";


export const HeroInventory = observer(() => {
  useEffect(() => {
    store.inventory.loadHeroInventory();
  }, [])

  const items = store.inventory.heroInventory;

  return (
    <div className='entity_inventory'>
      {items.map((item: ItemInterface) => (
        <EntityInventoryItem key={item._id} item={item}>
          <button onClick={() => heroStore.toggleFavourite(item)}>{heroStore.isFavourite(item) ? 'unfavourite' : 'favourite'}</button>
          <button onClick={() => heroStore.useItem(item)}>use</button>
          <button onClick={() => heroStore.removeItem(item)}>remove</button>
        </EntityInventoryItem>
      ))}
    </div>
  )
})
