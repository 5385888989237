import React from "react";
import './EntityInventory.scss'
import {EntityInventoryItem, OnItemClick} from "./EntityInventoryItem";
import {observer} from "mobx-react";
import {ItemInterface} from "../../../../types/item";


export const EntityInventory = observer(({items, onItemClick}: {items: ItemInterface[], onItemClick: OnItemClick}) => {
  return (
    <div className='entity_inventory'>
      {items.map((item: ItemInterface) => <EntityInventoryItem key={item._id} item={item} onItemClick={onItemClick}/>)}
    </div>
  )
});
