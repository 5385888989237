import React from "react";
import {observer} from "mobx-react";
import "./TabsComponent.scss"

export const TabComponent = ({children, onClick, active}: React.PropsWithChildren<{onClick: () => any, active?: boolean}>) =>
  (<div onClick={onClick} className={`tabs_component__tab ${active ? 'is-active' : ''}`}>{children}</div>)

export const TabsComponent = observer(({children}: React.PropsWithChildren<any>) => {
  return (
    <div className='tabs_component'>
      {children}
    </div>
  )
})
