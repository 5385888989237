
import {getDistance as getGeolibDistance} from "geolib";
import {GenericEntityInterface} from "./types/entity";

export const getDistance = (a: [number, number], b: [number, number]) => {
  const toGeolibCoordinates = (e) => ({lng: e[0], lat: e[1]})

  return getGeolibDistance(
    toGeolibCoordinates(a),
    toGeolibCoordinates(b)
  )
}

export const getDistanceBetweenEntities = (a: GenericEntityInterface, b: GenericEntityInterface) => {
  return getDistance(
    a.location.coordinates,
    b.location.coordinates
  )
}
