import React, { useRef, useEffect, useState } from 'react';
// @ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoicGF3ZWxicnpleiIsImEiOiJja2puZmhkdHUybjhxMnJvN3dtOHF2dWRsIn0.2bln9ZpwdFZNCQBkJ54yqQ';

export default function MapboxComponent({onMap}: {onMap?: (map: mapboxgl.Map) => any}) {
  const mapContainer = useRef<null>(null) as any; // todo
  const map = useRef<mapboxgl.Map|null>(null);
  const [lng, setLng] = useState(14);
  const [lat, setLat] = useState(54);
  const [zoom, setZoom] = useState(18);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      pitch: 60,
      fadeDuration: 0,
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });
    map.current.on('load', () => {
      if (!map.current) return;

      onMap && onMap(map.current);
    })
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      const mapInstance = map.current as any;
      setLng(mapInstance.getCenter().lng.toFixed(4));
      setLat(mapInstance.getCenter().lat.toFixed(4));
      setZoom(mapInstance.getZoom().toFixed(2));
    });
  });

  return (
    <div ref={mapContainer} className="map-container" />
  );
}
